
body {
	font-family: 'Montserrat', sans-serif;
	padding: 0px;
	margin: 0px;
}
input, textarea, button, select {
	font-family: 'Montserrat', sans-serif;
}
.content {
	width: 1200px;
	margin: 0px auto;
	padding: 55px 20px;
	max-width: calc(100% - 30px);
}
.title {
	text-transform: uppercase;
	font-size: 36px;
	margin-top: 15px;
	margin-bottom: 35px;
}
.right {
	text-align: right;
}
.center {
	text-align: center;
}
a {
	text-decoration: none;
}
.grid {
	display: grid;
	align-items: center;
	height: 100%;
}
img {
	max-width: 100%;
}
/* -------------------------------------- */

footer ul,
header ul {
	padding-left: 0px;
	margin: 0px;
}
footer li,
header li {
	display: inline-block;
}
footer li a,
header li a {
	background-color: white;
	color: #2B2B2B;
	font-size: 16px;
	display: block;
	height: 40px;
	line-height: 40px;
	border: 1px solid #F1F1F1;
	border-radius: 10px;
	margin-left: 5px;
	margin-right: 5px;
	padding-left: 15px;
	padding-right: 15px;
}
footer li a {
	font-size: 14px;
	padding-left: 10px;
	padding-right: 10px;
	margin: 0px 2px;
}
.header__login {
	color: #3A0CA3;
}
header .content {
	padding-bottom: 30px;
}

/* -------------------------------------- */
.banner {
	background-image: url('../image/banner_bg.png');
	background-repeat: no-repeat;
	background-position: center top;
}
.banner_human {
	height: 500px;
	margin: 0px auto;
}
.banner_soc {
	padding-left: 0px;
	margin: 0px;
}
.banner_soc li {
	display: inline-block;
}
.banner_soc li img {
	margin-right: 10px;
}
.banner h2 {
	margin: 0px;
	color: #4361EE;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.banner h1 {
	color: #0B090A;
	font-size: 48px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-top: 10px;
	margin-bottom: 40px;
}
.banner__btns {
	margin-top: 25px;
}
.banner__btns a {
	display: inline-block;
	background-color: #3A0CA3;
	padding: 20px 100px;
	border-radius: 40px;
	margin-right: 20px;

	font-size: 16px;
	font-style: normal;
	color: white;
	font-weight: 600;
}
.banner__content {
	margin-top: 65px;
	margin-bottom: 65px;
}
.banner__mini {
	margin-top: 50px;
}
.banner__mini-wrapper {
	display: flex;
	background-color: white;
	border-radius: 55px;
	padding: 25px 25px 25px 25px;
	box-shadow: 0 15px 20px rgba(0,0,0,0.1);
	margin-left: 0px;
	margin-right: 0px;
}
.bammer-user__img {
	width: 35px;
	height: 35px;
	background-position: center;
	background-size: contain;
	border: 2px solid white;
	border-radius: 50%;
	display: inline-block;
	margin-right: -20px;
}

.banner__mini-imgs {
	flex: 0 0 50%;
	padding-top: 5px;
	max-width: 50%;
}

.banner__mini-wrapper .banner__mini-text {
	color: #2B2B2B;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.wrapper-singe__img .bammer-user__img {
	height: 60px;
	width: 60px;

	background-position: center;
	background-size: cover;
}
.wrapper-singe__img {
	padding: 12px 25px 12px 25px;
}
.wrapper-singe__img .banner__mini-imgs {
	flex: 0 0 90px;
	padding-top: 5px;
	max-width: 90px;
}

/* -------------------------------------- */
.how-work__swiper {
	border-radius: 50px;
	border: 1px solid #9B51E0;
	padding: 10px 17px;
}
.how-work__swiper-item {
	font-weight: 400;
	height: 100%;
	text-align: center;
	border-radius: 40px;
	padding-top: 10px;
	padding-bottom: 10px;
	color: #3A0CA3;
}
.how-work__swiper-item:hover,
.how-work__swiper-item.active {
	cursor: pointer;
	background-color: #3A0CA3;
	color: white;
}
.how-work__content {
	margin-top: 40px;
}
.how-work__content-slide {
	border: 4px solid #9B51E0;
	height: 300px;
	background-position: center top;
	background-size: cover;
	border-radius: 10px;
}
.how-work__content h3 {
	color: #3A0CA3;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-top: 0px;
	margin-bottom: 15px;
}
.how-work__content p {
	margin-top: 10px;
	color: #2B2B2B;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 60px;
}

.how-work__content button {
	border: none;
	display: inline-block;
	background-color: #9B51E0;
	padding: 20px 70px;
	border-radius: 40px;
	font-size: 16px;
	font-style: normal;
	color: white;
	font-weight: 600;
}

/* -------------------------------------- */
.how-work__wrapper h3 {
	margin: 0px;
	color: #3A0CA3;
	text-align: center;
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%;
}
.how-work__wrapper {
	padding: 25px 20px 30px 20px;
	border-radius: 20px;
	border: 1px solid #3A0CA3;
}
.how-work__wrapper .grid {
	height: 85px;
}
.how-work__price1 {
	text-align: center;
	margin-top: 24px;
	padding-bottom: 10px;
	color: #323437;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
}
.how-work__price1 span {
	color: #9B51E0;
	text-align: center;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
}
.how-work__wrapper.active {
	background-color: #3A0CA3;
}
.how-work__wrapper.active h3,
.how-work__wrapper.active div,
.how-work__wrapper.active span {
	color: white !important;
}
.how-work__wrapper {
	position: relative;
}
.how-work__wrapper a,
.how-work__wrapper button {
	text-align: center;
	width: 100%;
	margin-top: 18px;
	border: none;
	display: inline-block;
	background-color: #3A0CA3;
	padding: 13px 0px;
	border-radius: 40px;
	font-size: 14px;
	font-style: normal;
	color: white;
	font-weight: 600;
}
.how-work__wrapper.active a,
.how-work__wrapper.active button {
	background-color:white;
	color: #3A0CA3;
}
.how-work__rec {
	border-radius: 100px;
	background: #9B51E0;
	color: white;
	position: absolute;
	padding: 7px 30px;
	left: 50%;
	top: -17px;
	transform: translate(-50%, 0);
}
.catalog__integrations h3 {
	font-size: 14px;
	line-height: 1;
}
.catalog__integrations input {
	width: 100%;
}
.catalog__integrations select {
	margin-bottom: 10px;
	border: 1px solid rgba(0,0,0,0.1) !important;
}
.catalog__integrations {
	padding-right: 10px;
}
.catalog-bloger button {
	border: none;
}

/* -------------------------------------- */
.start-work__wrapper {
	background-color: #3A0CA3;
	background-image: url('../image/circle.png');
	background-position: top -40px right -40px;
	background-size: 120px;
	background-repeat: no-repeat;
	padding: 45px;
	border-radius: 20px;
}
.start-work__wrapper h2 {
	color: #FFF;
	font-size: 42px;
	font-style: normal;
	font-weight: 600;
	line-height: 50px; /* 119.048% */
	margin-top: 0px;
}
.start-work h3 {
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	margin-top: 0px;
	line-height: normal;
	margin-bottom: 40px;
}
.start-work p {
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	margin-top: 0px;
	margin-bottom: 5px;
	line-height: 26px; /* 162.5% */
}


.start-work a {
	border: none;
	display: inline-block;
	background-color: #9B51E0;
	padding: 20px 90px;
	position: relative;
	border-radius: 40px;
	font-size: 16px;
	font-style: normal;
	color: white;
	font-weight: 600;
}
.start-work a svg {
	position: absolute;
	top: 9px;
	margin-left: 20px;
}

.why-blogerito {
	background: #3A0CA3;
}

.why-blogerito h3 {
	margin-top: 0px;
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0px;
}
.why-blogerito h2 {
	color: #FFF;
	font-size: 42px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-top: 10px;
}
.why-blogerito__item {
	background-color: white;
	border-radius: 10px;
	padding: 30px;
	margin-bottom: 30px;
	height: calc(100% - 40px);
}
.why-human {
	margin-left: -40px;
}
.why-blogerito__list {
	margin-top: 55px;
}
.why-blogerito__image {
	width: 83px;
	height: 83px;
	flex: 0 0 83px;
	border-radius: 50%;
	box-shadow: 4px 10px 30px 0px rgba(67, 97, 238, 0.40);
	background-position: center;
	background-size: cover;
	margin-right: 25px;
}
.why-blogerito__image-wrapper {
	display: flex;
}
.why-blogerito__title h4 {
	margin: 0px;

	color: #3A0CA3;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.why-blogerito__item p {
	color: #808080;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	margin-top: 35px;
	font-weight: 400;
	margin-bottom: 20px;
	line-height: normal;
}

/* -------------------------------------- */
.ind-blogers h3 {
	margin-top: 0px;
	color: #4361EE;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0px;
}
.ind-blogers h2 {
	color: #000;
	font-size: 42px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-top: 10px;
}
.ind-blogers__desc {
	color: #808080;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	width: 700px;
	max-width: 100%;
	margin-top: 45px;
}
.ind-blogers__list {
	margin-top: 35px;
	margin-bottom: 50px;
}
.ind-blogers__img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-position: center;
	background-size: cover;
	margin: 0px auto;
}
.ind-blogers__item {
	margin-bottom: 20px;
	text-align: center;
}
.ind-blogers__item p {
	color: #808080;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.ind-blogers .ba {
	border: none;
	display: inline-block;
	background-color: #3A0CA3;
	padding: 20px 70px;
	border-radius: 40px;
	font-size: 16px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-bottom: 40px;
}

@media (max-width: 996px) {
	footer li a, header li a {
		margin-bottom: 10px;
	}
	.why-human {
		display: none;
	}
	.how-work__wrapper {
		margin-bottom: 30px;
	}
	.start-work .right {
		text-align: center;
		margin-top: 30px;
	}
	.banner_human {
		display: none;
	}
}
@media (max-width: 760px) {
	.start-work__wrapper h2 {
		font-size: 28px;
		line-height: 1.2;
	}
	.how-work__content-slide {
		height: 225px;
		margin-top: 40px;
	}

	.ind-blogers h2,
	.why-blogerito h2 {
		font-size: 36px;
	}
	.ind-blogers h2 br {
		display: none;
	}
	footer li a, header li a {
		margin: 5px;
		padding: 0px 10px;
	}
	.banner__mini {
		margin-top: 10px;
	}
	.banner_soc li img {
		width: 40px;
	}
	header .content {
		padding-bottom: 0px;
	}
	.banner__content {
		margin-top: 0px;
	}
	.col-sm-five {
		width: 100%;
		flex: 0 0 100%;
	}
	.how-work__content p {
		margin-bottom: 30px;
	}
	.how-work__content-slide {
		margin-top: 25px;
	}
	.banner__mini-wrapper {
		margin-bottom: 20px;
	}
	.banner h1 {
		font-size: 28px !important;
		margin-bottom: 25px !important;
	}
	.new-program .form-item__wrapper {
		padding: 30px !important;
	}
	.catalog .title {
		margin-top: 20px !important;
	}
	.catalog .catalog-selecter__text,
	.catalog form select {
		width: 100% !important;
	}
	.bloger-work p {
		height: auto;
		min-height: 1px !important;
	}
	.how-works h2 {
		font-size: 32px !important;
	}
	.start-work a {
		padding: 15px;
		text-align: center;
	}
	.banner__btns a {
		margin-right: 0px;
		margin-bottom: 20px;
	}
	.banner__content {
		text-align: center;
		margin-top: 10px;
		margin-bottom: 20px;
	}
	footer .right,
	header .right {
		text-align: center;
	}
	footer ul,
	header ul {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	footer,
	header {
		text-align: center;
	}
	.content {
		padding: 35px 20px;
	}
}

/* -------------------------------------- */

footer {
	background: linear-gradient(180deg, #EFF1FD 0%, #CED6FB 100%), #D9D9D9;
}
.underline {
	margin-top: 15px;
}
.underline p {
	color: #2B2B2B;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 23px; /* 164.286% */
	margin: 0px;
}

/* -------------------------------------- */
.form-item__wrapper {
	background-color: #3A0CA3;
	padding: 30px 40px;
	border-radius: 20px;
	background-image: url(../image/circle.png);
	background-position: top -40px left -40px;
	background-size: 120px;
	background-repeat: no-repeat;
}
.form-item__wrapper form {
	border: 2px solid white;
	padding: 50px 30px;
	border-radius: 20px;
}
.form-item__wrapper form label {
	width: 100%;
}
.form-item__wrapper form span {
	width: 100%;
	display: block;
	text-align: right;
	margin-top: -10px;
}
.form-item__wrapper form span a {
	text-decoration: underline;
	color: white;
	font-size: 12px;
}

.form-item__wrapper form button {
	border: none;
	background-color: #9B51E0;
	padding: 10px 40px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 5px;
}
.form__new-account {
	color: white;
	text-align: right;
	margin-bottom: 0px;
}
.form__new-account a {
	color: white;
	text-decoration: underline;
	font-size: 13px;
}
.form-item__wrapper form select,
.form-item__wrapper form input {
	width: 100%;
	height: 55px;
	margin-bottom: 20px;
	border: none;
	border-radius: 20px;
	padding: 20px;
	font-size: 14px;
}
.form-item__wrapper form textarea {
	width: 100%;
	height: 140px;
	margin-bottom: 20px;
	border: none;
	border-radius: 20px;
	padding: 20px;
	font-size: 14px;
	resize: none;

}
.form-item__wrapper form select {
	padding: 10px 20px;
}

.form-item h1 {
	color: white;
	font-size: 36px;
	margin-top: 0px;
	margin-bottom: 0px;
}
.form-item h2 {
	font-size: 20px;
	margin-top: 10px;
	margin-bottom: 0px;
	color: white;
}

.form-item {
	min-height: calc(100vh - 400px);
}
.reg-text {
	text-align: left;
	font-size: 12px;
}

@media (max-width: 760px) {
	.form-item__wrapper form {
		margin-top: 35px;
		border: none;
		padding: 0px;
	}
	.project-list__content h3 span {
		display: block;
		margin-left: 0px;
		margin-top: 5px;
	}
	.project-list__item {
		padding: 20px !important;
	}
	.ftitle {
		font-size: 26px !important;
	}
	.form-item h2 {
		font-size: 18px;
		margin-bottom: -15px;
	}
	.form-item__wrapper {
		padding: 30px 20px;
	}
	.project-list__content {
		padding-left: 20px !important;
	}
}


.questions .title {
	margin-top: 0px;
}

.questions-item__title {
	background-color: #3A0CA3;
	position: relative;
	color: white;
	padding: 20px 55px 20px 20px;
	border-radius: 20px;
	font-size: 16px;
}
.questions-item__title svg {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translate(0px, -50%) rotate(-90deg);
}
.questions-item {
	margin-bottom: 20px;
}
.questions-item__desc {
	padding: 0px 20px;
	margin-top: 10px;
	font-size: 16px;
	line-height: 1.4;
}

.questions-item__desc {
	display: none;
}
.questions-item.active .questions-item__desc {
	display: block;
}

.contact__info p {
	margin-top: 9px;
	margin-bottom: 9px;
	line-height: 1.4;
}
@media (max-width: 760px) {
	.contact__info {
		margin-bottom: 25px;
	}
}

/* -------------------------------------- */
.bloger-banner h1 {
	font-size: 42px;
	line-height: 1.2;
	margin-bottom: 25px;
}
.bloger-banner {
	overflow: hidden;
}
.bloger-banner h2 {
	margin-bottom: 10px;
}
.bloger-banner .banner_human {
	height: 500px;
	width: auto !important;
	margin: 0px auto;
	max-width: initial;
	margin-top: -110px;
}

/* -------------------------------------- */
.how-works {
	background-color: #3A0CA3;
	background-image: url('../image/how-work_d1.png');
	background-position: left -40px top -40px;
	background-repeat: no-repeat;
}
.how-works__dots {
	width: 100%;
	height: 100%;
	background-position: right -40px bottom -40px;
	background-repeat: no-repeat;
}
.how-works .content {
	padding-bottom: 0px !important;
}
.how-works h3 {
	margin-top: 0px;
	color: #FFF;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0px;
}
.how-works h2 {
	color: #FFF;
	font-size: 42px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-top: 10px;
}
.how-works__item {
	background-color: white;
	border-radius: 10px;
	padding: 10px 15px 10px 85px;
	background-size: 40px;
	background-position: left 20px center;
	background-repeat: no-repeat;
	margin-top: 20px;
	margin-bottom: 170px;
}
.how-works img {
	width: 300px;
	margin: 0px auto;
}
.how-works__item h4 {
	color: #4361EE;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 0px;
}
.how-works__item p {
	margin-top: 10px;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.how-works__img {
	position: absolute;
	top: -120px;
	right: 120px;
}
.how-works .content {
	position: relative;
}
.blog-list__wrapper {
	background-color: #EBEEFD;
	border-radius: 20px;
	width: 100%;
	background-image: url('../image/dasc.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 250px;
	height: calc(100% - 30px);
}

.blog-list__item {
	box-shadow: 10px 10px 15px rgba(0,0,0,0.1);
	padding: 20px;
	border-radius: 80px;
	display: flex;
	margin-bottom: 30px;
}
.blog-list__img {
	height: 60px;
	width: 60px;
	margin-right: 20px;
	border-radius: 50%;
	background-color: #3A0CA3;
	background-position: center;
	background-repeat: no-repeat;
}

.bloger-wh p {
	text-align: left;
	color: #3A0CA3;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.bloger-wh {
	background-color: #3A0CA3;
	background-image: url(../image/how-work_d1.png);
	background-position: left -40px top -40px;
	background-repeat: no-repeat;
}
.bloger-wh > div {
	background-image: url(../image/how-work_d2.png);
	width: 100%;
	height: 100%;
	background-position: right -40px bottom -40px;
	background-repeat: no-repeat;
}

.blogers-example .row {
	margin-bottom: 10px;
}
.blogers-example h3 {
	color: #3A0CA3;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.blogers-example p {
	color: #2B2B2B;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
line-height: 1.4;
}
.blogers-example img {
	border: 12px solid rgb(155, 81, 224);
	border-radius: 20px;
}

.bloger-work p {
	color: #FFF;
	font-size: 15px;
	font-style: normal;
	line-height: 1.5;
	font-weight: 300;
	text-transform: uppercase;

	min-height: 85px;
}

@media (max-width: 960px) {
	.how-works img {
		display: none;
	}
	.how-works .img-min {
		display: none;
	}
}

@media (max-width: 760px) {
	.how-works__item {
		margin-top: 0px !important;
		margin-bottom: 20px !important;
	}
	.title {
		font-size: 28px;
	}
	.bloger-work p {
		margin-bottom: 30px;
	}
	.bloger-work .col-md-6 {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.order-1 {
		order: 1;
	}
	.order-2 {
		text-align: center;
		order: 2;
	}
}


.new-program h1 {
	font-size: 26px
}
.new-program form {
	width: 1000px;
	max-width: 100%;
	margin: 0px auto;
}

.form-item__wrapper label {
	color: white;
}
.form-item__wrapper textarea,
.form-item__wrapper input,
.form-item__wrapper select {
	margin-top: 14px;
}

.project-list__item {
	background: linear-gradient(180deg, #EFF1FD 0%, #CED6FB 100%), #3A0CA3;
	border-radius: 20px;
	margin-bottom: 20px;
	padding: 40px 35px;
	display: flex;
}
.project-list__item h3 {
	margin-top: 0px;
	font-size: 24px;
	padding-bottom: 5px;
}
.project-list__item .logo {
	border-radius: 50%;
	background-size: cover;
	background-position: center;
	height: 66px;
	width: 66px;
}
.project-list__content {
	padding-left: 50px;
	flex: 0 0 calc(100% - 66px);
}
.project-list__content h3 {
	color: #3A0CA3;
	margin-top: 0px;
	font-size: 20px;
	font-style: normal;
	margin-bottom: 14px;
	font-weight: 600;
	line-height: normal;
}
.project-list__content h3 span {
	margin-left: 20px;
	color: #9B51E0;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.28px;
}
.project-list__content p {
	color: #2B2B2B;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0px;
}


.myprogramms__morebtnitem,
.project-list button {
	border: none;
	display: inline-block;
	background-color: #3A0CA3;
	padding: 15px 60px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin: 0px auto;
	margin-top: 35px;
	display: list-item;
}

.contact-form__wrapper {
	background-color: #3A0CA3;
	padding: 30px 40px;
	border-radius: 20px;
	background-image: url(../image/circle.png);
	background-position: top -40px left -40px;
	background-size: 120px;
	background-repeat: no-repeat;
}
.contact-form__wrapper h3 {
	color: #FFF;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-top: 0px;
	margin-bottom: 22px;
}
.contact-form__img {
	background-image: url('../image/frame.png');
	width: 100%;
	height: calc(100% - 71px);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.contact-form__wrapper p {
	color: #FFF;
	font-size: 19px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.contact-form__wrapper input {
	width: 100%;
	height: 55px;
	margin-bottom: 20px;
	border: none;
	border-radius: 20px;
	padding: 20px;
	font-size: 14px;
}

.contact-form__wrapper textarea {
	width: 100%;
	height: 140px;
	margin-bottom: 20px;
	border: none;
	border-radius: 20px;
	padding: 20px;
	font-size: 14px;
	resize: none;
}
.contact-form__wrapper button {
	border: none;
	background-color: #9B51E0;
	padding: 10px 40px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 5px;
}

.ftitle {
	margin-top: 0px;
	color: #0B090A;
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}


.myprogramms__wrapper {
	border-radius: 30px;
	border: 1.5px solid #3A0CA3;
	padding: 30px 30px;
}

.myprogramms__wrapper .date {
	margin-top: 0px;
	margin-bottom: 0px;
	color: #9B51E0;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.28px;
}
.myprogramms__wrapper .name {
	margin-top: 10px;
	color: #3A0CA3;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.myprogramms__logo {
	display: flex;
}
.myprogramms__logo-img {
	height: 66px;
	flex: 0 0 66px;
	width: 66px;
	background-position: center;
	background-size: cover;
	border-radius: 50%;
}
.myprogramms__logo-content {
	flex: 0 0 calc(100% - 66px);
	padding-left: 20px;
}
.myprogramms__string{
	display: flex;
	justify-content: space-between;
}
.myprogramms__more {
	text-align: center;
	border: none;
	background-color: #9B51E0;
	padding: 15px 40px;
	border-radius: 40px;
	font-size: 16px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 5px;
	display: block;
	width: 100%;
	margin-top: 25px;

}
.myprogramms__none {
	pointer-events: none;
	opacity: 0.5;
}
.myprogramms__blue-text p {
	color: #FFF;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.myprogramms__blue-text {
	margin-top: 30px;
	background: #3A0CA3;
	margin-left: -30px;
	margin-right: -30px;
	padding: 10px 27px;
}
.myprogramms__subtitle {
	color: #3A0CA3;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.myprogramms__logo-content h4 {
	margin-top: 0px;
	color: #0B090A;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 0px;
}
.myprogramms__logo-content p {
	color: #777;
	margin-top: 10px;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.myprogramms__morebtn button {
	margin-top: 15px;
	padding: 15px 0px;
}


.bloger-info__userpic {
	border-radius: 50%;
	width: 206px;
	height: 206px;
	margin: 0px auto;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.sub-count {
	border-radius: 15px;
	background: #3A0CA3;
	padding: 13px 15px;
	margin-bottom: 30px;
	text-align: center;
}
.sub-count p {
	text-align: center;
	margin: 0px;
	color: #FFF;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.sub-count h3 {
	color: #FFF;
	margin: 0px;
	margin-top: 0px;
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%; /* 41.6px */
}

.sub-prog__progerss {
	height: 4px;
	background: #DD79DB;
}
.sub-prog__progerss > div {
	height: 4px;
	background: #4361EE;
}
.sub-prog {
	margin-top: 17px;
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid rgba(0,0,0,0.2);
}

.sub-prog__img {
	position: relative;
	height: 30px;
}
.sub-prog__img > div {
	position: absolute;
}
.sub-prog__img .one {
	left: 0;
}

.sub-prog__img .two {
	right: 0;
}

.sub-prog__rew p {
	margin: 7px 5px 7px 0px;
}
.sub-prog__rew {
	display: flex;
}
.sub-prog__rew > div {
	flex: 0 0 50%;
}
.sub-prog__proc {
	display: flex;
	border-radius: 10px;
	border: 1.5px solid #3A0CA3;
	background: #FFF;
}
.sub-prog__proc > div {
	padding: 7px 10px;
	flex: 0 0 50%;

	color: #3A0CA3;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.32px;
}

.bloger-info__status {
	display: flex;
}

.bloger-info__prize-wrapper {
	border-radius: 20px;
	background: #F8F4FF;
	padding: 7px 10px 7px 30px;
	background-image: url('../image/rbl.svg');
	background-repeat: no-repeat;
	background-size: 14px;
	background-position: center left 10px;
}
.bloger-info__prize-wrapper p {
	margin: 0px;
	color: #001A34;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.26px;
}

.bloger-info__status-text {
	padding: 7px 15px;
	color: #4CAB20;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	&.is-frozen {
		color: #ff3939;
	}
}

.bloger-info__title {
	color: #3A0CA3;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.bloger-info__title span {
	color: #C4BCD8;
	font-size: 16px;
	margin-left: 10px;
}
.bloger-info__minidesc {
	color: #0B090A;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.bloger-info__category {
	margin-top: 0px;
	border-bottom: 1px solid rgba(0,0,0,0.2);
	padding-bottom: 10px;
	margin-bottom: 10px;
	color: #777;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.bloger-info__dop {
	margin: 0px;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding: 10px 10px 10px 35px;
	background-repeat: no-repeat;
	background-position: left center;
	margin-bottom: 0px;
}
.bloger-info__dop.active {
	color: #4CAB20;
}
.bloger-info__dop .bloger-info__dop-sub {
	display: block;
	color: rgba(0,0,0,0.4);
	font-size: 12px;
}

.bloger-info__maintext {
	margin-top: 55px;
}
.bloger-info__maintext p {
	margin-top: 0px;
	color: #000;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.25;
	letter-spacing: 0.4px;
	max-height: none !important;
	overflow: hidden;
}
.bloger-info__maintext {
}
.bloger-info__maintext.shadow {
	position: relative;
}
.bloger-info__maintext.shadow p {
	max-height: 200px !important;
}
.bloger-info__maintext.shadow::after {
	background: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0));
	content: " ";
	display: block;
	width: 100%;
	height: 100px;
	max-height: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
}


.bloger-page__review {
	background-color: #3A0CA3;
	background-image: url(../image/how-work_d1.png);
	background-position: left -40px top -40px;
	background-repeat: no-repeat;
}
.how-works__dots {
	width: 100%;
	background-image: url(../image/how-work_d2.png);
	height: 100%;
	background-position: right -40px bottom -40px;
	background-repeat: no-repeat;
}

.bloger-page__review h2 {
	color: #FFF;
	font-size: 42px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-top: 0px;
	margin-bottom: 30px;
}
.bloger-page__review h2 > span {
	color: #C186F9;
	font-size: 42px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.how-works__dots select {
	height: 30px;
	width: 200px;
	margin-bottom: 20px;
	border: none;
	border-radius: 20px;
	padding: 0px 14px;
	font-size: 14px;
}
.bloger-page__review-item {
	background-color: white;
	padding: 30px;
	border-radius: 20px;
	margin-top: 30px;
}

.bloger-page__review-item h3 {
	color: #3A0CA3;
	font-size: 16px;
	font-style: normal;
	font-weight: 800;
	line-height: 20px; /* 125% */
	letter-spacing: 0.2px;
	margin-top: 0px;
}
.stars-line {
	text-align: center;
}

.bloger-page__review-item h3 > span {
	margin-left: 10px;
	color: #DD79DB;
	font-size: 16px;
	font-style: normal;
	font-weight: 800;
	line-height: 20px; /* 125% */
	letter-spacing: 0.2px;
}

.bloger-page__review-item p {
	color: #0B090A;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 19.5px; /* 139.286% */
	letter-spacing: 0.2px;
}
.bloger-info__review-date {
	margin-top: 15px;
	text-align: center;
	margin-bottom: 20px;
	color: #777;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 153.846% */
	letter-spacing: 0.2px;
}

.bloger-page__review-item .myprogramms__logo {
	margin-top: 20px;
}
.bloger-page__review-item a {
	color: #3A0CA3;
	text-decoration: underline;
}

.bloger-page__review .myprogramms__morebtnitem {
	background-color: #9B51E0;
	margin-top: 70px;
	margin-bottom: 30px;
}

.new-program .form-item__wrapper {
	padding: 50px 45px;
}
.new-program .form-item__wrapper form {
	border: 0px;
	padding: 0px;
	margin-top: 50px;
}
.new-program .form-item__wrapper input {
	border: 2px solid white;
	border-radius: 10px !important;
	color: black !important;
}

.new-program .form-item__wrapper select,
.new-program .form-item__wrapper textarea,
.new-program .form-item__wrapper input {
	margin-top: 18px !important;
	margin-bottom: 25px !important;
}
.new-program .form-item__wrapper select {
	border-radius: 10px !important;
}
.new-program .form-item__wrapper button {
	margin: 0px auto;
	display: block;
	margin-top: 24px;
	padding: 15px 45px;
}

.dop-file {
	background-image: url(../image/screp.svg);
	background-position: left center;
	padding-left: 50px;
	margin-bottom: 35px;
	margin-top: 5px;
	background-repeat: no-repeat;
	background-size: 35px;
	color: white;
}

@media (max-width: 760px) {
	.order1 {
		order: 1;
	}
	.order2 {
		order: 2;
		margin-top: 30px;
		margin-bottom: 50px;
	}
	.order3 {
		order: 3;
	}
	.user-info,
	.header__logo {
		margin: 0px auto !important;
	}
}


.user-info__img {
	cursor: pointer;
	height: 66px;
	width: 66px;
	background-position: center;
	background-size: cover;
	border-radius: 50%;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjMOJe9B8AAzEB30tjwp8AAAAASUVORK5CYII=); /* fallback blue 1x1px image */
}
.user-info {
	display: flex;
	width: 190px;
	margin-right: 0px;
	margin-left: auto;
}
.user-info__icon {
	margin-right: 14px;
}

.popup-setting,
.popup-msg {
	background-color: rgba(0,0,0,0.4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
}
.popup-setting > div,
.popup-msg > div {
	background-color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	padding: 30px 20px;
	width: 400px;
	max-width: 98%;
}
.popup-msg h3 {
	text-align: center;
	margin-top: 10px;
	margin-bottom: 30px;
}
.popup-msg .popup-msg__link {
	background-color: #3A0CA3;
	color: white;
	padding: 10px 20px;
	font-size: 14px;
	margin: 0px auto;
	display: block;
	width: 150px;
	text-align: center;
	border-radius: 5px;
	cursor: pointer;
}




.form-item__wrapper input,
.form-item__wrapper textarea,
.form-item__wrapper select {
	width: 100%;
	height: 55px;
	margin-bottom: 20px;
	border: 1px solid #3A0CA3;
	border-radius: 20px;
	padding: 20px;
	font-size: 14px;
	margin-top: 10px;
}
.form-item__wrapper textarea {
	height: 100px;
	resize: none;
	padding: 10px 20px;
}
.form-item__wrapper select {
	line-height: 20px;
	padding: 15px 20px;
}

.form-item__wrapper button {
	border: none;
	background-color: #9B51E0;
	padding: 10px 40px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 5px;
}

.soc-input {
	border: 1px solid white;
	padding: 20px;
	margin-bottom: 25px;
	margin-top: 10px;
	border-radius: 10px;
}
.soc-input h3 {
	color: white;
	margin-top: 0px;
	line-height: 1;
}
.soc-input h3 span {
	font-size: 14px;
	font-weight: 400;
}

.form-item__wrapper input[type="file"] {
	border: 1px solid white;
	padding: 15px 20px 20px 20px;
}
.form-item__wrapper input[type="checkbox"] {
	width: 15px;
	height: 15px;
	margin-bottom: 5px;
	top: 1px;
	position: relative;
}
.soc-input label {
	width: 100%;
	display: block;
}


.user-info__img > div {
	position: absolute;
	z-index: 999;
	background-color: white;
	padding-top: 7px;
	top: 100%;
	right: 0;
	width: 200px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0,0,0,0.07);
}
.user-info__img > div a {
	margin: 0px;
	border: none;
	width: 200px;
	font-size: 15px;
	padding: 8px 15px;
	height: auto;
	line-height: 1.2;
	text-align: left;
	background: none;
}
.user-info__img ul {
	margin: 0px !important;
}

.user-info__img-exit {
	margin-top: 7px;
	border-top: 1px solid rgba(0,0,0,0.05);
}
.user-info__img-exit a {
	padding: 11px 15px !important;
}

.bloger-info .dop-file {
	color: black;
	cursor: pointer;
	display: block;
	background-image: url(../image/sc_002.svg);

	width: 185px;
	margin: 0px auto;
	margin-top: 17px;
}

.account__setting a {
	border: none;
	display: inline-block;
	background-color: #9B51E0;
	padding: 15px 60px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin: 0px auto;
	margin-top: 15px;
}

.account__setting {
	text-align: center;
	margin-bottom: 85px;
	padding-left: 15px;
	padding-right: 15px;
}



.pay-page {
	margin-top: 30px;
}
.pay-page > div {
	display: flex;
	text-align: center;
	margin-top: 10px;
}
.pay-page > div > div {
	cursor: pointer;
	width: 30px;
	height: 30px;
	border: 1px solid #3A0CA3;
	border-radius: 5px;
	line-height: 28px;
	font-size: 16px;
	font-weight: 500;
	margin-right: 5px;
}
.pay-page > div > div:hover,
.pay-page > div > div.active {
	background-color: #3A0CA3;
	color: white;
}

.application-list .seller-info {
	border-left: 1px solid rgba(0,0,0,0.07);
	border-right: 1px solid rgba(0,0,0,0.07);
}
.application-list .myprogramms__morebtn {
	margin-top: 20px;
}
.application-list .item-desc p {
	font-size: 14px;
	color: #777;
}

.item-desc {
	padding-top: 10px;
	margin-top: 10px;
	border-top: 1px solid rgba(0,0,0,0.07);
}

.search-line {
	position: relative;
}
.search-line input {
	width: 100%;
	border: 2px solid #3A0CA3;
	border-radius: 15px 50px 50px 15px;
	font-size: 15px;
	padding: 18px 70px 18px 14px;
}
.search-line button {
	width: 60px;
	text-align: center;
	background-color: #3A0CA3;
	border: none;
	position: absolute;
	right: 0;
	height: 59px;
	border-radius: 0px 50px 50px 0px;
}

.integrations-list .myprogramms__wrapper {
	margin-top: 30px;
}

.integration-item .title {
	margin-top: 0px;
}

.integration-item__image {
	display: block;
	margin: 0px auto;
	width: 200px;
	height: 200px;
	background-position: center;
	background-size: cover;
	border-radius: 50%;
}
.integration-item__product h2 {
	text-align: center;
	font-weight: 500;
	font-size: 18px;
	margin-top: 25px;
	margin-bottom: 15px;
}

.integration-item__product {
	padding: 0px 15px;
	border-right: 1px solid rgba(0,0,0,0.1);
}

.integration-item__desc {
	color: #777;
	margin-top: 0px;
	font-size: 15px;
	font-style: normal;
	line-height: 1.4;
}

.integration-item__seller {
	padding-bottom: 10px;
	margin-bottom: 25px;
	border: 1px solid rgba(0,0,0,0.1);
	border-radius: 10px;
	padding: 20px 25px;
}
.integration-item .myprogramms__subtitle {
	margin-bottom: 10px;
	margin-top: 0px;
}
.integration-item__seller .item-desc {
	margin-bottom: 0px;
}

.integration-item__btn {
	border-top: 1px solid rgba(0,0,0,0.1);
	border-bottom: 1px solid rgba(0,0,0,0.1);
	padding: 20px 0px;
	text-align: right;
}
.integration-item__btn button {
	text-align: center;
	border: none;
	background-color: #9B51E0;
	padding: 15px 40px;
	border-radius: 40px;
	font-size: 16px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-bottom: 5px;
	margin-left: 15px;
	display: inline-block;
}


.catalog .title {
	font-size: 32px;
	margin-top: 0px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.catalog .title span {
	color: #9B51E0;
}
.catalog-filter__item {
	margin-bottom: 27px;
}
.catalog-filter__item label {
	width: 100%;
	display: block;
	margin-bottom: 10px;
}

.catalog .search-line {
	margin-bottom: 42px;
}
.catalog form select {
	border: 1.5px solid #B3BCC5;
	padding: 7px 12px;
	border-radius: 10px;
	width: 250px;
	font-size: 12px;
	max-width: 100%;
	color: #3A0CA3;
	font-weight: 600;
}

.catalog-bloger {
	position: relative;
	border: 2px solid #3A0CA3;
	border-radius: 30px;
	font-size: 15px;
	padding: 25px 50px 25px 20px;
	margin-top: 25px;
}

.catalog__userpic {
	width: 100%;
	max-width: 100%;
	padding-top: 100%;
	height: auto;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.catalog-bloger .bloger-info__prize {
	width: 100%;
	max-width: 100%;
	padding-top: 100%;
	height: auto;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	padding-top: 0px;
	margin-bottom: 15px;
}

.catalog .bloger-info__title {
	margin-top: 5px;
	line-height: 1;
	margin-bottom: 0px;
}

.catalog__desc {
	overflow: hidden;
	height: 53px;
	margin-bottom: 12px;
	color: #0B090A;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.catalog__active {
	margin-top: 5px;
	height: 18px;
	color: #4CAB20;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.catalog .sub-count {
	padding: 20px 10px;
}
.catalog .sub-count h3 {
	line-height: 1;
}

.catalog .sub-prog__img {
	height: 34px;
}
.catalog .sub-prog {
	padding-top: 11px;
	margin-bottom: 12px;
	padding-bottom: 16px;
}
.catalog .bloger-info__category {
	margin-bottom: 12px;
}
.catalog .catalog__integrations {
	min-height: 156px;
	border-bottom: 1px solid rgba(0,0,0,0.2);
	padding-bottom: 5px;
}

.catalog-more {
	background-color: #3A0CA3;
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	width: 45px;
	height: 100%;
	text-align: center;
	border-radius: 0px 25px 25px 0px;
}
.catalog-more svg {
	top: 50%;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
}

.catalog__integrations h3 {
	margin-top: 0px;
	font-weight: 500;
	margin-bottom: 14px;
}
.catalog__integrations ul {
	min-height: 80px;
	margin-top: 0px;
	padding-left: 0px;
	list-style: none;
	margin-bottom: 0px;
	color: #0B090A;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.catalog__integrations p {
	margin-bottom: 0px;
	margin-top: 15px;
	font-size: 14px;
}

.catalog-cost h3 {
	margin-top: 13px;
	color: #9B51E0;
	font-size: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: 1;
	margin-bottom: 0px;
}
.catalog-cost span {
	font-size: 18px;
	color: black;
	font-weight: 400;
}
.catalog-cost p {
	margin-top: 10px;
	margin-bottom: 0px;
}
.catalog__soc img {
	margin-right: 5px;
}
@media (max-width: 760px) {
	.pay-form {
		margin-top: 10px;
	}
	.pay-form button {
		margin-top: 10px !important;
	}
	.catalog .title {
		margin-bottom: 10px;
		font-size: 22px;
	}
	.catalog-bloger {
		padding: 25px 15px 25px 15px;
		padding-right: 65px !important;
	}
	.catalog__desc,
	.bloger-info__title {
		text-align: center;
	}
	.catalog__userpic {
		width: 125px;
		height: 125px;
		padding: 0px;
		margin: 0px auto;
		margin-bottom: 15px;
	}
	.catalog__desc {
		height: auto;
	}
	.catalog__integrations,
	.sub-count {
		margin-top: 30px;
	}
}

.catalog-filter input {
	margin-right: 10px;
}
.catalog-filter button {
	border: none;
	background-color: #9B51E0;
	padding: 10px 40px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-top: 0px;
	margin-bottom: 5px;
}

.item-cost p {
	margin-top: 20px;
	border-bottom: 1px solid rgba(0,0,0,0.2);
	font-size: 15px;
	padding-bottom: 5px;
}
.item-cost h3 {
	margin-top: 10px;
	color: #9B51E0;
	font-size: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: 1;
	margin-bottom: 0px;
}
.item-cost h3 span {
	font-size: 18px;
	color: black;
	font-weight: 400;
}

.item-cost button {
	margin-top: 20px;
	width: 100%;

	border: none;
	background-color: #9B51E0;
	padding: 12px 40px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-bottom: 10px;
}

.popup-order {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: rgba(0,0,0,0.5);
	z-index: 999;
}
.popup-order > div {
	background-color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 15px 15px 25px 15px;
	border-radius: 10px;
	width: 500px;
	max-width: 100%;
	transform: translate(-50%, -50%);
	max-height: 98%;
}
.popup-close {
	text-align: right;
}
.popup-close svg {
	cursor: pointer;
}

.popup-order label {
	width: 100%;
	display: block;
	font-size: 14px;
}
.popup-order select {
	width: 100%;
	height: 55px;
	margin-bottom: 20px;
	border: 1px solid #3A0CA3;
	border-radius: 20px;
	padding: 0px 20px;
	font-size: 14px;
	margin-top: 10px;
	border: 1px solid rgba(0,0,0,0.2);
}
.popup-order textarea,
.popup-order input {
	width: 100%;
	height: 55px;
	margin-bottom: 20px;
	border: 1px solid #3A0CA3;
	border-radius: 20px;
	padding: 0px 20px;
	font-size: 14px;
	margin-top: 10px;
	border: 1px solid rgba(0,0,0,0.2);
}
.popup-order textarea {
	padding: 10px 20px;
	height: 60px;
	resize: none;
}

.popup-order form {
	margin-top: 20px;
}

.popup-order button {
	margin-top: 10px;
	border: none;
	background-color: #9B51E0;
	padding: 10px 40px;
	border-radius: 40px;
	font-size: 14px;
	font-style: normal;
	color: white;
	font-weight: 600;
}

.none-integrations {
	padding: 10px 20px;
	text-align: center;
}
.none-integrations a {
	border: none;
	background-color: #9B51E0;
	padding: 10px 40px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-top: 10px;
	display: inline-block;
	margin-bottom: 0px;
}

.pay-list__item {
	border-radius: 30px;
	border: 1.5px solid #ccd5dd;
	padding: 15px 25px;
	margin-bottom: 20px;
	line-height: 1.4;
}

.dop-file.project input {
	margin: 0px !important;
	height: 0px;
	padding: 0px;
	border: none;
	display: block;
}

.integration-chat {
	border: 1px solid rgba(0,0,0,0.1);
	padding: 15px;
	background-color: #3A0CA3;
	border-radius: 10px;
}

.integration-chat__mspace > button {
	display: none !important;
}
.integration-chat__mspace > div {
	padding: 15px;
}
.integration-chat__mspace {
	background-color: white;
	border: 1px solid rgba(0,0,0,0.1);
	border-radius: 10px;
	height: 500px;
	overflow-x: hidden;
	overflow-y: auto;
}
.integration-chat form {
	margin-top: 20px;
}
.integration-chat textarea {
	width: 100%;
	border: 1px solid rgba(0,0,0,0.1);
	height: 80px;
	resize: none;
	padding: 10px;
	border-radius: 10px;

}

.integration-chat__userinfo {
	font-size: 13px;
	margin-top: 10px;
	opacity: 0.75;
}
.integration-chat__userinfo span {
	float: right;
	font-size: 12px;
}
.integration-chat button {
	border: none;
	background-color: #9B51E0;
	padding: 10px 40px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 5px;
}
.integration-chat__msg > div {
	background-color: #f0f1f5;
	border-radius: 10px;
	min-width: 300px;
	width: 65%;
	max-width: calc(100% - 100px);
	margin-bottom: 10px;
	padding: 10px;
}
.integration-chat__msg.other > div {
	margin-right: 0px;
	margin-left: auto;
	background-color: #9B51E0;
	color: white;
}


.chat-list {
	border: 1px solid rgba(0,0,0,0.1);
	height: 100%;
	border-radius: 10px;
	padding: 10px;
}

.chat-list__item {
	display: flex;
	padding: 5px 10px;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	cursor: pointer;
}
.chat-list__logo {
	flex: 0 0 75px;
	width: 75px;
	height: 75px;
}
.chatName {
	width: 100%;
	padding: 10px;
	margin-bottom: 14px;
	border: 1px solid rgba(0,0,0,0.2);
	border-radius: 10px;
}
.chat-list__logo > div {
	width: 100%;
	padding-top: 100%;
	background-position: center;
	background-size: cover;
	border-radius: 50%;
	margin-top: 5px;
}
.notifications__list-date {
	font-size: 12px;
	text-align: right;
	margin-bottom: 5px;
	color: rgba(0,0,0,0.2);
}
.notifications__list span > a {
	display: contents !important;
}
.chat-list__desc {
	padding-left: 12px;
}
.chat-list__desc h3 {
	margin-top: 5px;
	margin-bottom: 8px;
	font-size: 15px;
	line-height: 1.2;
}
.chat-list__desc p {
	font-size: 13px;
	color: rgba(0,0,0,0.5);
	margin: 0px 0px 0px 0px;
}
.integration-chat__text {
	overflow-x: hidden;
	font-size: 14px;
	padding-bottom: 10px;
}
.integration-chat__text a {
	color: white;
	text-decoration: underline;
}
.chat-list__item.active,
.chat-list__item:hover {
	background-color: rgba(0,0,0,0.13);
}
.myprogramms select {
	margin-bottom: 15px;
	border: 1.5px solid #B3BCC5;
	padding: 7px 15px;
	border-radius: 10px;
	width: 250px;
	max-width: 100%;
	color: #3A0CA3;
	font-weight: 600;
}
@media (max-width: 760px) {
	.integration-chat__msg > div {
		max-width: 100%;
		width: calc(100% - 30px);
		min-width: calc(100% - 30px);
	}
}

header .header__login {
	background-color: #3A0CA3;
	color: white;
	width: 100px;
	text-align: center;
	padding: 10px;
	border-radius: 10px;
}
.how-work__content-item a {
	background-color: #3A0CA3;
	color: white;
	text-align: center;
	padding: 10px 20px;
	border-radius: 10px;
}

button, a {
	cursor: pointer !important;
}


.user-score {
	border: 1px solid rgba(0,0,0,0.1);
	border-radius: 10px;
	padding: 25px 20px;
	margin-bottom: 40px;
}
.user-score h3 {
	font-size: 22px;
	margin-top: 0px;
	margin-bottom: 15px;
}
.user-score p {
	margin-bottom: 0px;
	margin-top: 5px;
}

.pay-list select {
	width: 100%;
	margin-top: 5px;

	margin-bottom: 15px;
	border: 1.5px solid #B3BCC5;
	padding: 7px 15px;
	border-radius: 10px;
	max-width: 100%;
	color: #3A0CA3;
	font-weight: 600;
}
.pay-list button {
	background-color: #3A0CA3;
	padding: 15px 10px;
	border-radius: 40px;
	width: 100%;
	font-size: 16px;
	font-style: normal;
	color: white;
	font-weight: 600;
	border: none;
}
.pay-form label {
	width: 100%;
	font-size: 14px;
}

@media (min-width: 760px) {
	.grid.right {
		float: right;
	}
}
@media (max-width: 760px) {
	.grid.right {
		display: block;
	}
	.pay-list button {
		margin-top: 25px;
	}
}

.integration__applications-list {
	padding: 15px 25px;
	border: 1px solid rgba(0,0,0,0.1);
	margin-bottom: 30px;
	border-radius: 10px;
}
.integration__applications-list h4 {
	margin-top: 0px;
	font-size: 20px;
	margin-bottom: 15px;
}
.applic-item {
	border: 2px solid #3A0CA3;
	border-radius: 10px;
	padding: 15px;
	display: flex;
	margin-bottom: 15px;
	height: calc(100% - 15px);
}
.applic-item .applic-item__image {
	flex: 0 0 100px;
}
.applic-item .applic-item__image .img {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	margin-top: 10px;
	border-radius: 50%;
	background-color: orange;
	width: 100%;
	padding-top: 100%;
}
.applic-item .applic-item__content {
	flex: 0 0 calc(100% - 100px);
}
.applic-item__content {
	padding-left: 20px;
}
.applic-item__content h3 {
	color: #3A0CA3;
	margin-top: 0px;
	margin-bottom: 0px;
}
.applic-item__content p {
	margin-top: 10px;
	margin-bottom: 0px;
}

.applic-item button {
	text-align: center;
	border: none;
	background-color: #9B51E0;
	padding: 10px 30px;
	border-radius: 40px;
	font-size: 14px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-bottom: 0px;
	display: block;
	margin-top: 17px;
}

.telegram__sub {
	background-color: white;
	border-radius: 10px;
	padding: 25px 20px 10px 20px;
	margin-top: 30px;
}
.telegram__sub p {
	font-size: 15px;
	margin: 0px;
}
.telegram__sub button {
	margin-top: 15px;
	margin-bottom: 10px;
}
.telegram__sub hr {
	margin-bottom: 20px;
}

.telegram__unlink {
	margin-bottom: 13px !important;
	font-size: 16px !important;
}
.telegram__unlink span {
	color: red;
	cursor: pointer;
	text-decoration: underline;
	font-weight: 500;
	margin-left: 7px;
}

.loadingBlock {
	min-height: calc(100vh - 414px);
}
.loadingBlock p {
	color: #3A0CA3;
	font-size: 17px;
	font-weight: 500;
	text-align: center;
}

.loader {
	width: 75px;
	height: 75px;
	margin: 0px auto;
	border-radius: 50%;
	perspective: 800px;
}
.inner {
	position: absolute;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	border-radius: 50%;
}
.inner.one {
	left: 0%;
	top: 0%;
	animation: rotate-one 1s linear infinite;
	border-bottom: 3px solid #3A0CA3;
}
.inner.two {
	right: 0%;
	top: 0%;
	animation: rotate-two 1s linear infinite;
	border-right: 3px solid #3A0CA3;
}
.inner.three {
	right: 0%;
	bottom: 0%;
	animation: rotate-three 1s linear infinite;
	border-top: 3px solid #3A0CA3;
}
@keyframes rotate-one {
	0% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}
@keyframes rotate-two {
	0% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}
@keyframes rotate-three {
	0% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}

.user-info__icon-count {
	cursor: pointer;
	width: 25px;
	height: 25px;
	background-color: #3A0CA3;
	color: white;
	text-align: center;
	font-size: 12px;
	line-height: 25px;
	border-radius: 50%;
	position: absolute;
	top: 5px;
	right: -10px;
}
.integ-info__icon-count {
	cursor: pointer;
	width: 20px;
	height: 20px;
	background-color: #3A0CA3;
	color: white;
	text-align: center;
	font-size: 10px;
	line-height: 20px;
	border-radius: 50%;
	display: block;
	position: absolute;
	top: -13px;
	right: 25px;
}

.notifications__list {
	height: 300px;
	overflow-y: auto;
	position: absolute;
	z-index: 999;
	background-color: white;
	padding-top: 7px;
	top: 100%;
	right: 0;
	width: 200px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0,0,0,0.07);
	padding: 10px;
}
.notifications__list li {
	display: block;
	padding: 10px 5px;
	text-align: left;
	font-size: 14px;
	border-bottom: 1px dashed rgba(0,0,0,0.15);
}
.notifications__list button {
	margin-top: 10px;
	width: 100%;
	color: rgba(0,0,0,0.3);
	font-size: 15px;
	background: none;
	border: none;
}

.integration-chat form > span {
	display: inline-block;
	width: calc(100% - 170px);
	text-align: right;
	text-decoration: underline;
	cursor: pointer;
}

.popupChat {
	background-color: rgba(0,0,0,0.3);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
	z-index: 99999;
}
.popupChat > div {
	background-color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	padding: 30px 20px;
	width: 400px;
	max-width: 98%;
}
.popupChat h3 {
	margin-top: 0px;
	padding-bottom: 0px;
}
.popupChat button {
	margin: 5px;

	border: none;
	background-color: #9B51E0;
	padding: 10px 20px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
}
.popupChat {
	text-align: center;
}

.integration-chat__conf {
	width: 100%;
	max-width: 100%;
	background-color: #3A0CA3;
	padding: 15px;
	border-radius: 10px;
}
.integration-chat__conf h3 {
	color: white;
	margin-top: 7px;
	margin-bottom: 10px;
	font-size: 16px;
}
.integration-chat__conf label {
	color: white;
	margin-top: 8px;
	display: block;
	font-size: 15px;
}
.integration-chat__conf label span {
	margin-top: 5px;
	margin-left: 10px;
}
.integration-chat__conf label svg {
	position: relative;
	top: 4px;
	width: 20px;
	height: 20px;
	margin-right: 3px;
	cursor: pointer;
}
.button-chatrev {
	margin-top: 10px;
}
.button-chatrev button {
	padding: 10px 30px;
	height: 35px;
	line-height: 1;
	margin-right: 10px;
	font-size: 13px;
}

.video-list__item iframe {
	height: 200px;
	border: none;
	border-radius: 10px;
}
.video-list__item p {
	margin: 0px;
	margin-top: 10px;
	margin-bottom: 5px;
	font-size: 16px;
	text-align: center;
}
.video-list__item {
	padding: 10px;
	border: 1px solid rgba(0,0,0,0.07);
	border-radius: 10px;
	margin-bottom: 25px;
}

.popup-video {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.6);
	z-index: 999;
}
.popup-video svg {
	position: absolute;
	cursor: pointer;
	right: 15px;
	top: 15px
}

.popup-video iframe {
	position: absolute;
	top: 50%;
	left: 50%;

	transform: translate(-50%, -50%);
	border: none;
	max-width: 99%;
	max-height: 99%;
}

.type-sellect {
	position: relative;
}
.type-sellect__text {
	width: 100%;
	background-color: white;
	color: black;
	margin-top: 18px;
	margin-bottom: 25px;
	padding: 10px 25px;
	border-radius: 10px;
	height: 55px;
	line-height: 35px;
	font-size: 14px;
	background-image: url('../image/arrow-sellect.svg');
	background-size: 9px;
	background-repeat: no-repeat;
	background-position: right 3px center;
	cursor: pointer;
}
.type-sellect__options {
	position: absolute;
	width: 100%;
	background-color: white;
	border-radius: 10px;
	margin-top: -25px;
	padding: 10px;
	border-top: 1px solid #3A0CA3;
}
.type-sellect__options label {
	cursor: pointer;
	color: black;
	display: block;
	padding: 5px;
}
.new-program .form-item__wrapper .type-sellect__item input {
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	margin-right: 10px;
}

.catalog-selecter__sellect {
	position: relative;
}
.catalog-selecter__text {
	font-size: 12px;
	padding: 7px 12px;
	border-radius: 10px;
	width: 250px;
	max-width: 100%;
	color: #3A0CA3;
	font-weight: 600;
	margin-bottom: 10px;
	border: 1px solid rgba(0,0,0,0.1) !important;

	background-image: url('../image/arrow-sellect.svg');
	background-size: 9px;
	background-repeat: no-repeat;
	background-position: right 3px center;
	cursor: pointer;
}
.popup-order .type-sellect__text > div {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.catalog-selecter__text > div {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	height: 16px;
}

.popup-order select,
.catalog__integrations select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	text-indent: 1px;
	text-overflow: '';

	background-image: url('../image/arrow-sellect.svg');
	background-size: 9px;
	background-repeat: no-repeat;
	background-position: right 3px center;
	cursor: pointer;
}

.catalog-selecter__options {
	position: absolute;
	width: 100%;
	background-color: white;
	border-radius: 10px;
	margin-top: -11px;
	padding: 5px;
	border: 1px solid rgba(0,0,0,0.1) !important;

	background-color: white;
	z-index: 9;
}

.type-sellect__item {
	display: block;
	margin-top: 2px;
	margin-bottom: 2px;
	font-size: 13px;
	line-height: 1.2;
	padding-top: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid rgba(0,0,0,0.1) !important;
}
.catalog-selecter__sellect input {
	width: 20px !important;
	margin: 0px;
	position: relative;
	top: 2px;
}

.popup-order .type-sellect__text {
	width: 100%;
	height: 55px;
	margin-bottom: 20px;
	border: 1px solid #3A0CA3;
	border-radius: 20px;
	padding: 0px 20px;
	font-size: 14px;
	margin-top: 10px;
	border: 1px solid rgba(0,0,0,0.2);
	line-height: 55px;
}
.popup-order .type-sellect__options {
	border: 1px solid rgba(0,0,0,0.2);
}
.popup-order .type-sellect__options input {
	height: 15px;
	width: 15px;
	margin: 0px;
	position: relative;
	top: 2px;
	margin-right: 10px;
}
.popup-order .type-sellect__options label {
	padding: 7px 5px;
}

.chat-list {
	height: 552px;
	overflow-x: hidden;
	overflow-y: auto;
}
.db-item {
	padding-bottom: 25px;
	margin-bottom: 35px;
	border-bottom: 1px solid rgba(0,0,0,0.1);
}
.db-item h3 {
	margin-top: 0px;
}
.db-item__img {
	height: 200px;
	width: 100%;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	box-shadow: 0 0 5px rgba(0,0,0,0.2);
	border-radius: 10px;
}

@media (max-width: 760px) {
	.db-item__img {
		margin-bottom: 20px;
	}
	.chat-list {
		max-height: 350px;
		margin-bottom: 20px;
	}
	.dop-file {
		margin-bottom: 28px !important;
	}
}
.cancel-text {
	width: 100%;
	resize: none;
	height: 120px;
	border-radius: 10px;
	border: 1px solid rgba(0,0,0,0.2);
	margin-bottom: 10px;
	padding: 12px;
}
.notifications__list a {
	padding: 0px;
	height: 20px;
	line-height: 20px;
	font-size: 13px;
	margin-top: 5px;
	border: none;
	margin-left: 0px;
	text-decoration: underline;
	color: #3A0CA3;
}

.form-item__wrapper input {
	background-color: white !important;
}

.dopimage {
	display: block;
	background-color: white;
	padding: 20px;
	border-radius: 10px;
	text-align: center;
	margin-bottom: 15px;
}
.dopimage img {
	max-height: 250px;
	width: auto;
	max-width: 100%;
}
.project-list__item-cont {
	border-top: 1px solid rgba(0,0,0,0.2);
	margin-top: 10px;
	padding-top: 10px;
}
.project-list__item-cont p {
	margin: 5px 0px;
}
.project-btns {
	margin-top: 14px;
}
.project-btns button {
	border: none;
	background-color: #9B51E0;
	padding: 10px 40px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 5px;
	margin-right: 10px;
}
.popup-integration {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.4);
	z-index: 9;
}
.popup-integration > div {
	background-color: #3A0CA3;
	width: 700px;
	max-width: 98%;
	max-height: 96%;
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	padding: 25px;
	border-radius: 20px;
	background-image: url(../image/circle.png);
	background-position: top -40px left -40px;
	background-size: 120px;
	background-repeat: no-repeat;
	overflow-x: hidden;
	overflow-y: auto;
}
.popup-integration textarea,
.popup-integration select,
.popup-integration input {
	margin-top: 18px !important;
	margin-bottom: 25px !important;

	border: 2px solid white;
	border-radius: 10px !important;
	color: black !important;

	width: 100%;
	height: 55px;
	margin-bottom: 20px;
	border: none;
	border-radius: 20px;
	padding: 20px;
	font-size: 14px;

	background-color: white;
}
.popup-integration label {
	color: white;
}

.popup-integration textarea {
	width: 100%;
	height: 140px;
	margin-bottom: 20px;
	border: none;
	border-radius: 20px;
	padding: 20px;
	font-size: 14px;
	resize: none;
}

.popup-integration svg {
	margin-bottom: 20px;
	cursor: pointer;
}
.popup-integration button {

	border: none;
	background-color: #9B51E0;
	padding: 10px 40px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-top: -5px;
	margin-bottom: 5px;
}


.popup-integration .type-sellect__item input {
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	margin-right: 10px;
}

.popup-integration input[type="checkbox"] {
	width: 15px !important;
	height: 15px !important;
	margin-bottom: 5px !important;
	top: 1px !important;
	position: relative !important;
}

.type-sellect__item {
	color: black !important;
}
.type-sellect__text {
	overflow: hidden !important;
}

/* -------------------------------------- */
.project-list__actual {
	background: linear-gradient(180deg, #EFF1FD 0%, #CED6FB 100%), #3A0CA3;
	border-radius: 20px;
	margin-bottom: 20px;
	padding: 40px 35px;
}
.project-list__actual h2 {
	margin-top: 0px;
	font-size: 26px;
	margin-bottom: 35px;
	color: #9B51E0;
}
.project-list_logo {
	margin: 0px auto;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-position: center;
	background-size: cover;
}
.project-list__section {
	border-top: 1px solid rgba(0,0,0,0.2);
	border-bottom: 1px solid rgba(0,0,0,0.2);
	margin: 20px 0px;
	padding: 20px 0px;
}
.project-list__actual-desc p {
	margin-top: 0px;
	font-size: 16px;
	margin-bottom: 7px;
}
.project-list__actual-btns {
	border-left: 1px solid rgba(0,0,0,0.2);
	padding-left: 20px;
}
.project-list__actual-btns button {
	display: block;
	border: none;
	background-color: #9B51E0;
	padding: 10px 40px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-top: 0px;
	margin-bottom: 7px;
	margin-right: 10px;
}
.project-list__actual-errors {
	color: rgb(209, 3, 3);
	cursor: pointer;
}
.project-list__actual h3 {
	font-size: 24px;
	margin-top: 0px;
}
.project-list__actual h3 span {
	font-size: 14px;
	margin-left: 25px;
}
.project-list__actual h3 span span {
	text-decoration: underline;
	margin-left: 10px;
}
.project-list__actual table {
	text-align: left;
	width: 100%;
}
.project-list__actual table tr > th:nth-child(1) {
	width: 30px;
}
.project-list__actual table tr > th:nth-child(2) {
	width: 35%;
}
.project-list__actual table tr > th:nth-child(3) {
}
.project-list__actual table svg {
	position: relative;
	top: 4px;
	margin-left: 10px;
}
.error-btn {
	margin-left: 20px;
	background-color: rgb(27, 130, 62);
	color: white;
	border: none;
	padding: 10px;
	float: right;
	border-radius: 40px;
}
.project-list__more-btn {
	margin-top: 20px;
	font-size: 14px !important;
	border-bottom: 1px solid #3A0CA3;;
	display: inline-block;
	color: #3A0CA3;
}
.project-list__blogers .project-list__blogers-content {
	font-size: 13px;
}
.project-list__blogers a {
	color: #3A0CA3;
	font-size: 17px;
	text-decoration: underline;
	margin-right: 10px;
}
.project-list__blogers-btns {
	text-align: right;
}
.project-list__blogers-btns span {
	font-size: 14px !important;
	text-decoration: none;
	margin-left: 20px;
	cursor: pointer;
}
.project-list__blogers .row {
	margin-bottom: 10px;
}

/* -------------------------------------- */
.popup-lesson,
.popup-form,
.dashbord-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0,0,0,0.3);
	z-index: 99999;
}
.popup-lesson > div,
.popup-form > div,
.dashbord-popup > div {
	border-radius: 10px;
	background-color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 20px 25px;
	width: 700px;
	max-width: calc(100% - 20px);
	transform: translate(-50%, -50%);
	max-height: 98%;
	overflow-x: hidden;
	overflow-y: auto;
}
.popup-form > div {
	width: 500px;
}
.popup-form label {
	display: block;
}
.dashbord-popup > div h2 {
	font-size: 18px;
	margin-top: 0px;
	margin-bottom: 20px;
	text-align: center;
}
.less .popup-lesson__desc {
	padding-left: 20px !important;
	background-image: none !important;
}
.popup-lesson__desc,
.dashbord-popup__desc {
	padding: 15px 20px;
	padding-left: 100px;
	background-image: url('../image/user-bg.png');
	background-size: 70px;
	background-position: top 15px left 15px;
	background-repeat: no-repeat;
	background-color: #f3f3f6;
	border-radius: 10px;
	margin-bottom: 20px;
}
.popup-lesson__desc p,
.dashbord-popup__desc p {
	margin: 0px;
	line-height: 1.5;
	color: #000;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
}
.popup-lesson__desc p {
	margin-bottom: 15px;
}
.popup-lesson__desc iframe {
	width: 100%;
	height: 300px;
	border: none;
}
.popup-lesson__btns span {
	color: #808080;
	padding-top: 10px;
	padding-bottom: 10px;
	display: block;
	font-size: 12px;
}
.popup-lesson__btns button {
	border: none;
	background-color: #9B51E0;
	padding: 10px 40px;
	border-radius: 40px;
	font-size: 13px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-top: 0px;
	margin-bottom: 0px;
	margin-right: 0px;
	margin-left: auto;
	display: block;
}

.popup-lesson__desc p:last-child {
	margin-bottom: 0px;
}
.dashbord-popup__btns {
	display: block;
	height: 100%;
	border: 1px solid #3A0CA3;
	border-radius: 10px;
}
.dashbord-popup__btns > div {
	padding-top: 90%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: auto 85%;
}
.dashbord-popup__btns p {
	text-align: center;
	color: #3A0CA3;
	font-size: 14px;
	margin-top: 0px;
	padding: 0px 5px;
}
.dashbord-popup__close {
	cursor: pointer;
	text-align: center;
	margin-bottom: 0px;
	margin-top: 20px;
	color: rgba(0,0,0,1);
	font-size: 13px;
}

.popup-form__close {
	text-align: right;
	margin-bottom: 20px;
}
.popup-form__close svg {
	height: 35px;
	width: 35px;
	cursor: pointer;
}

.popup-form input,
.popup-form textarea {
	border: 1px solid #3A0CA3;
	width: 100%;
	margin-bottom: 20px;
	border-radius: 20px;
	padding: 20px;
	font-size: 14px;
	resize: none;
}
.popup-form textarea {
	height: 140px;
}
.popup-form button {
	border: none;
	background-color: #9B51E0;
	padding: 10px 40px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 5px;
}

@media (max-width: 760px) {
	.popup-lesson__btns {
		text-align: center;
	}
	.popup-lesson__btns button {
		margin: 0px auto;
		margin-top: 10px;
	}
	.popup-lesson__desc iframe {
		height: 200px;
	}
	.popup-lesson__desc,
	.dashbord-popup__desc {
		background-image: none;
		padding-left: 20px;
	}
	.dashbord-popup .row > div {
		margin-bottom: 20px;
	}
}



/* -------------------------- */
.dashbord-lessons__img {
	padding-top: 75%;
	background-position: center;
	background-size: cover;
}
.dashbord-lessons__item {
	display: block;
	border: 1px solid rgba(0,0,0,0.05);
	border-bottom: 5px solid rgba(0,0,0,0.05);
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: 20px;
	cursor: pointer;
	height: calc(100% - 20px);
}
.dashbord-lessons__item h3 {
	font-size: 14px;
	font-weight: 400;
	margin: 15px 10px 0px 10px;
	color: #808080;
}
.dashbord-lessons__item p {
	font-size: 12px;
	font-weight: 400;
	margin: 15px 10px 10px 10px;
	color: #ababab;
}
.dashbord-detail h1 {
	margin-top: 0px;
	margin-bottom: 30px;
}
.dashbord-detail p {
	color: #808080;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 20px;
	margin-top: 0px;
}

/* ------------------------------- */
.popup-error {
	width: 100%;
	height: 100vh;
	background-color: #0B090A;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;

	display: none;
}
.popup-error > div {
	position: absolute;
	top: 50%;
	left: 50%;
}

@media (max-width: 760px) {
	.project-list_logo {
		margin-bottom: 30px;
	}
	.project-list__actual h2 {
		margin-bottom: 15px;
	}
	.project-list__actual-btns {
		border-left: 0px;
		padding-left: 0px;
		margin-top: 20px;
	}
	.error-btn {
		display: block;
		float: none;
		margin-left: 0px;
		margin-top: 20px;
	}
	.project-list__section th {
		display: block;
		width: 100% !important;
	}
	.project-list__section tr {
		border-bottom: 1px solid rgba(0,0,0,0.2);
		display: block;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
	.project-list__actual table tr > th:nth-child(1) {
		max-width: 35px;
		display: inline-block;
	}
	.project-list__actual table tr > th:nth-child(2) {
		max-width: calc(100% - 35px);
		display: inline-block;
	}
	.project-list__blogers .row {
		border-bottom: 1px solid rgba(0,0,0,0.2);
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
	.project-list__blogers-btns {
		text-align: left !important;
	}
	.project-list__blogers-btns span {
		margin-left: 0px;
		margin-top: 10px;
		display: inline-block;
		margin-right: 10px !important;
	}
}


/* ------------------------------- */
.myprogramms-item {
	border-radius: 30px;
	border: 1.5px solid #3A0CA3;
	padding: 25px 30px 25px 30px;
	margin-bottom: 20px;
}
.myprogramms-item h2 {
	margin-top: 0px;
	font-size: 26px;
	margin-bottom: 35px;
	color: #3A0CA3;
}
.myprogramms-item .desc p {
	margin-top: 0px;
}
.myprogramms-item .desc .info {
	line-height: 1.3;
	font-size: 17px;
}
.myprogramms-item__btns span,
.myprogramms-item__btns a {
	display: inline-block;
	cursor: pointer;
	border: none;
	background-color: #9B51E0;
	padding: 10px 40px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-top: 0px;
	margin-bottom: 7px;
	margin-right: 10px;
}
.myprogramms-item__section {
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	margin: 20px 0px 0px 0px;
	padding: 20px 0px;
}
.myprogramms-item__section h3 {
	margin-top: 0px;
	margin-bottom: 20px;
	font-size: 18px;

	color: #3A0CA3;
}
.myprogramms-item__section ul {
	margin: 0px;
	padding: 0px;
	list-style: none;
}
.myprogramms-item__section ul li {
	margin-top: 7px;
	margin-bottom: 7px;
	font-size: 17px;
}
.myprogramms-item__section ul .active {
	color: #4CAB20;
}
.myprogramms-item__section ul li span {
	font-size: 13px;
	text-decoration: underline;
	cursor: pointer;
}
@media (max-width: 760px) {
	.myprogramms-item__section ul li span {
		display: block;
		margin-top: 4px;
		margin-bottom: 20px;
	}
}

/* --------------------------------------------- */
.popup-chat {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,0.2);
	width: 100%;
	height: 100vh;
	z-index: 999;
}
.popup-chat__close {

	/*text-align: right;*/
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fff;
}
.popup-chat__close svg {
	width: 30px;
	cursor: pointer;
	fill: white;
	height: 30px;
}
.popup-chat > div {
	overflow-x: hidden;
	overflow-y: auto;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 10px 15px 15px 15px;
	background-color: #3A0CA3;
	border-radius: 10px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 100%;
	max-height: 98vh;
	width: 800px;
}
.popup-chat > div .integration-chat__mspace {
	height: 500px;
}

/* ----------------------------- */
.temp-item {
	border: 1px solid #3A0CA3;
	border-radius: 10px;
	padding: 20px 15px;
	margin-bottom: 15px;
}
.temp-item h3 {
	font-size: 22px;
	font-weight: 500;
	margin-top: 0px;
	margin-bottom: 0px;
}
.temp-item p {
	margin-top: 10px;
	line-height: 1.25;
	margin-bottom: 10px;
}
.temp-item div {
	text-align: right;
	color: red;
	text-decoration: underline;
}
.active_red {
	color: red;
}


/* ----------------------------- */
.popup-image {
	background-color: rgba(0,0,0,0.2);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 99;
}
.popup-image > div {
	background-color: white;
	padding: 10px;
	border-radius: 10px;
	width: 800px;
	max-width: 98%;
	max-height: 98vh;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.popup-image__close {
	text-align: right;
}
.popup-image__close svg {
	cursor: pointer;
}
.popup-image img {
	max-width: 100%;
	max-height: 100%;
}
.popup-image__body {
	height: calc(98vh - 65px);
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.title_type2 {
	font-weight: 600;
	font-size: 42px;
}
.title_type2 span {
	color: #9b51e0;
}

/* ------------------------------------------------ */
.bloger-video iframe {
	width: 100%;
	height: 450px;
	border: none;
	border-radius: 20px;
}
@media (max-width: 760px) {
	.bloger-video iframe {
		height: 350px;
	}
}

/* ------------------------------------------------ */
.bloger-works .title {
	margin-bottom: 20px;
}
.subtitle {
	font-weight: 500;
	font-size: 16px;
	text-transform: uppercase;
	color: #4361ee;
}
.bloger-works__wrapper {
	margin-top: 50px;
	padding: 40px;
	border-radius: 20px;
	background-color: #EBEEFD;
}
.bloger-works .image > div {
	border-radius: 50%;
	width: 100px;
	height: 100px;
	background-position: center;
	background-size: cover;
}

.bloger-works__images {
	display: flex;
}
.bloger-works__images .image {
	flex: 0 0 100px;
	width: 100px;
}
.bloger-works__images .text {
	flex: 0 0 calc(100% - 100px);
	width: calc(100% - 100px);
	padding-left: 25px;
}
.bloger-works__text {
	margin-top: 30px;
	margin-bottom: 35px;
}
.bloger-works__text h3 {
	margin-top: 0px;
	font-weight: 600;
	font-size: 16px;
	color: #2b2b2b;
}
.bloger-works__text p {
	font-weight: 400;
}
.bloger-works__text p span {
	color: #9b51e0;
	font-weight: 600;
}
.bloger-works__item > div {
	padding-top: calc(100% - 20px);
	border-radius: 30px;
	background-position: center;
	background-size: cover;
	margin-left: 10px;
	margin-right: 10px;
}
.btn-juliet {
	width: 56px;
	height: 56px;
	background-position: center;
	background-size: contain;
	border: none;
	background-color: rgba(0,0,0,0);
	background-image: url('../image/slider-arrow.svg');
	position: absolute;
	top: calc(50% - 28px);
	z-index: 999;
}
.btn-juliet.prev {
	left: -35px;
}
.btn-juliet.next {
	right: -35px;
	transform: rotate(-180deg);
}
.bloger-works__images .text h3 {
	margin-top: 0px;
	margin-bottom: 0px;
	font-weight: 500;
	font-size: 20px;
	line-height: 180%;
	color: #0b090a;
}
.bloger-works__images .text p {
	margin-top: 0px;
	margin-bottom: 0px;

	font-weight: 400;
	font-size: 14px;
	color: #808080;
}
.bloger-wh .why-blogerito__image {
	font-family: var(--font-family);
	font-weight: 900;
	font-size: 50px;
	padding-top: 11px;
	color: #fff;
	text-align: center;
}



.every-day__wrapper {
	padding: 40px;
	border-radius: 20px;
	background-color: #9B51E0;
}
.every-day__wrapper h3 {
	margin-top: 0px;
	margin-bottom: 20px;
	font-weight: 600;
	font-size: 42px;
	text-transform: uppercase;
	color: #fff;
}
.every-day__wrapper p {
	font-weight: 500;
	font-size: 16px;
	text-transform: uppercase;
	color: #fff;
	margin: 0px;
}
.every-day__wrapper {
	position: relative;
}
.every-day__wrapper > img {
	position: absolute;
	width: 360px;
	height: 360px;
	top: -55px;
	right: -180px;
}

@media (max-width: 1600px) {
	.every-day__wrapper > img {
		width: 260px;
		height: 260px;
		top: -20px;
		right: -20px;
	}
}

@media (max-width: 1200px) {
	.every-day__wrapper > img {
		width: 200px;
		height: 200px;
	}
	.every-day__wrapper h3 {
		font-size: 28px;
	}
	.every-day__wrapper img {
		display: none;
	}
}
@media (max-width: 760px) {
	.title_type2 {
		font-size: 28px;
	}
	.bloger-examples__product {
		max-height: 350px;
		margin-top: 30px;
	}
}

/* ------------------------------------------------ */
.bloger-examples__product {
	width: 350px;
	height: 450px;
	max-width: 100%;
	box-shadow: 4px 10px 30px 0 rgba(0, 0, 0, 0.1);
	border-radius: 30px;
	border: 10px solid #9B51E0;
	background-position: center;
	background-size: cover;
}
.bloger-examples__seller {
	margin-top: 0px;
	font-weight: 600;
	font-size: 20px;
	line-height: 130%;
	color: #3a0ca3;
}
.bloger-examples__image {
	display: flex;
}
.bloger-examples__image .image {
	flex: 0 0 calc(100px);
	width: 100%;
}
.bloger-examples__image .image > div {
	width: 100px;
	height: 100px;
}
.bloger-examples__image {
	margin-top: 20px;
}
.bloger-examples__image .text {
	padding-left: 25px;
	font-weight: 500;
	font-size: 18px;
	line-height: 180%;
	color: #0b090a;
}
.bloger-examples__item h2 {
	margin-top: 20px;
	margin-bottom: 20px;
	font-weight: 600;
	font-size: 20px;
	line-height: 130%;
	color: #3a0ca3;
}
.bloger-examples__item p {
	margin-top: 0px;
	margin-bottom: 20px;
	font-weight: 400;
	font-size: 16px;
	color: #2b2b2b;
}
.bloger-examples__price div {
	font-weight: 500;
	font-size: 18px;
	color: #2b2b2b;
}
.bloger-examples__price div span {
	font-weight: 700;
	color: #9b51e0;
}
.bloger-examples__price div s {
	font-weight: 700;
	color: rgba(155, 81, 224, 0.5);
}
.bloger-examples__item {
	margin-top: 60px;
}
.bloger-examples__item .col {
	flex: 0 0 350px;
	max-width: 50%;
	width: 350px;
}
.bloger-examples__item .lblock {
	padding-left: 80px;
}
.bloger-examples__item .rblock {
	padding-right: 80px;
}

@media (max-width: 960px) {
	.bloger-examples__item .rblock,
	.bloger-examples__item .lblock {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.start-work .content {
		padding-left: 0px;
		padding-right: 0px;
	}
	.start-work a {
		padding: 10px 30px !important;
		text-align: center;

	}
	.start-work a svg {

		display: none;
	}
}
@media (max-width: 760px) {
	.bloger-examples__item .col {
		max-width: calc(100%);
	}
	.start-work__wrapper {
		text-align: center;
	}
}

.fb-footer {
	padding-left: 65px;
	text-align: left;
	position: relative;

	font-weight: 500;
	font-size: 15px;
	color: #3a0ca3;
}
.fb-footer img {
	left: 0;
	top: -5px;
	position: absolute;
}

.btn-instr {
	text-align: right;
}
.btn-instr a {
	display: inline-block;
	margin-right: 0;
	margin-left: auto;
	background-color: rgb(27, 130, 62);
	color: white;
	padding: 10px 25px;
	border-radius: 20px;
}
@media (max-width: 760px) {
	.btn-instr {
		text-align: center;
	}
}

/** new */
:root {
	--blue-tint: rgb(224 229 251 / 50%);
}

.content {
	&.short-padding {
		padding-top: 0;
	}

	.page {
		&.prices {
			display: grid;
			gap: 15px;

			& .tariffs {
				display: grid;
				background-color: var(--blue-tint);
				border-radius: 20px;
				overflow: hidden;

				& th, & tr:nth-of-type(even) {
					background-color: var(--blue-tint);
				}

				& th {
					padding: 20px 15px 15px 15px;
				}

				& td {
					padding: 15px;
				}

				& tbody > tr:last-of-type > td {
					padding-bottom: 20px;
				}
			}
		}
	}
}

