.table {
	width: 100%;
	border: 1.5px solid #ccd5dd;
	border-radius: 30px;
	border-collapse: collapse;
	padding: 16px;
	margin-bottom: 20px;
}
th {
	text-align: left;
	padding: 16px;
}

td {
	padding-left: 10px;
	text-align: left;
	height: 20px;
}
.tbody {
	padding-bottom: 16px;
}
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
}
.buttons {
	display: flex;
	gap: 8px;
}
@media (max-width: 960px) {
	.buttons {
		flex-direction: column;
	}
}
.input {
	border: 0;
	outline: 0;
}

.input:focus {
	outline: none!important;
}
