.modal {
	position: fixed;
	inset: 0;
	opacity: 0;
	pointer-events: none;
	z-index: -1;
}
.overlay {
	width: 100%;
	height: 100%;
	background: rgba(0 0 0 / 60%);
	display: flex;
	align-items: center;
	justify-content: center;
}

.content {
	padding: 20px;
	border-radius: 12px;
	transition: 0.3s transform;
	transform: scale(0.5);
	background: #f8f8f8;
}

.opened {
	pointer-events: auto;
	opacity: 1;
	z-index: 10;
}
.opened .content {
	transform: scale(1);
}

.isClosing .content {
	transform: scale(0.2);
}

