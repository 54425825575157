.input {
	width: 100%;
	height: 25px;
	border: 1px solid #000;
	border-radius: 20px;
	padding: 20px;
	font-size: 14px;
}
.wrapper {
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
}

.button {
	/* height: 15px; */
	padding: 5px !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}
