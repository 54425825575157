.table {
	width: 100%;
	border-radius: 30px;
	border-collapse: collapse;
	padding: 16px;
	margin-bottom: 20px;
}
th {
	text-align: left;
	padding: 16px;
}

td {
	padding-left: 10px;
	text-align: left;
	height: 20px;
}
.tbody {
	padding-bottom: 16px;
}
