
.button {
	margin-top: 5px;
	margin-left: 15px;
	margin-right: 15px;
	background: #9B51E0;
	width: fit-content;
	display: inline-block;
	font-size: 15px
}
.wait {
	background: #ebdcf9;
	color: #0b090a;
	cursor: default !important;
}
.canceled {
	opacity: 0.5;
	cursor: default !important;
}
.text {
	color: #0b090a;
	font-size: 15px;
	width: 40%;
}
