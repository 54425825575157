.wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.input {
	width: 100%;
	height: 35px;
	border: none;
	border-radius: 20px;
	padding: 20px;
	font-size: 14px;
}
.cancelButton {
	border: none;
	background-color: #9B51E0;
	padding: 10px 40px;
	border-radius: 40px;
	font-size: 15px;
	font-style: normal;
	color: white;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 5px;
}
.acceptButton {
	text-align: center;
	padding: 10px 40px;
	border-radius: 40px;
	background-color: #3A0CA3;
	color: white;
	border: none;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 5px;
}
.buttons {
	width: 100%;
	display: flex;
	gap: 8px;
	justify-content: flex-end;
}
/* Tooltip container */
.tooltip {
	position: relative;
	display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	font-size: 14px;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;

	/* Position the tooltip text */
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;

	/* Fade in tooltip */
	opacity: 0;
	transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}
